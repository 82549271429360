import { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import CalendarWindow from '../CalendarWindow/CalendarWindow'
import { dateWithoutTime } from '../../../func'
let calendarFlag = false
const DateCalls = (props) => {
    const [getDate, setDate] = useState({ visible: false, begin: Date.now(), end: Date.now() })
    const selectDate = (value) => setDate(value)   
    useEffect(() => {
        if (getDate.visible === false && calendarFlag === true) {
            props.setDate(getDate)
            calendarFlag = false
        }
        else if (getDate.visible === true) calendarFlag = true
    },
        [getDate.visible])
    return (<>
        <CalendarWindow date={getDate} selectDate={selectDate}></CalendarWindow>
        <Button className='buttonLeft' size="sm" variant="outline-secondary"
            onClick={() => {
                const date = { visible: false, begin: Date.now(), end: Date.now() }
                setDate(date)
                props.setDate(date)
            }}>Сегодня</Button>
        <Button className='buttonMiddle' size="sm" variant="outline-secondary"
            onClick={() => {
                const date = { visible: false, begin: new Date(Date.now() - 86400000), end: new Date(Date.now() - 86400000) }
                setDate(date)
                props.setDate(date)
            }}>Вчера</Button>
        <Button className='buttonMiddle' size="sm" variant="outline-secondary"
            onClick={() => {
                const date = { visible: false, begin: new Date(Date.now() - 604800000), end: Date.now() }
                setDate(date)
                props.setDate(date)
            }}>Неделя</Button>
        <Button className='buttonMiddle' size="sm" variant="outline-secondary"
            onClick={() => {
                const date = { visible: false, begin: new Date(Date.now() - 2628000000), end: Date.now() }
                setDate(date)
                props.setDate(date)
            }}>Месяц</Button>
        <Button className='buttonRight' size="sm" variant="outline-secondary"
            onClick={() => {
                setDate({ visible: true, begin: getDate.begin, end: getDate.end })

            }}>{`${dateWithoutTime(props.date.begin)} – ${dateWithoutTime(props.date.end)}`}</Button>


    </>)
}
export default DateCalls
