import { useState, useEffect } from "react"
import { groupList } from "../../../config"
const HeaderInputCalls = (props) => {
    const [getGroup, setGroup] = useState('none')
    const [getTypeCall, setTypeCall] = useState('none')
    const [getComment, setComment] = useState('none')
    const [getFirstCall, setFirstCall] = useState('none')
    const [getRedirect, setRedirect] = useState('none')
    const [getCountCalls, setCountCalls] = useState(0)
    useEffect(() => {
        if (props.group.name !== 'Все') setGroup('')
        else setGroup('none')
    }, [props.group.name])
    useEffect(() => {
        if (props.typeCall !== 'Все' && props.typeCall !== undefined) setTypeCall('')
        else setTypeCall('none')
    }, [props.typeCall])
    useEffect(() => {
        if (props.comment !== 'Все' && props.comment !== undefined && props.comment !== 'Все') setComment('')
        else setComment('none')
    }, [props.comment])
    useEffect(() => {
        if (props.firstCall !== 'Все' && props.firstCall !== undefined) setFirstCall('')
        else setFirstCall('none')
    }, [props.firstCall])
    useEffect(() => {
        if (props.redirect !== 'Все' && props.redirect !== undefined) setRedirect('')
        else setRedirect('none')
    }, [props.redirect])
    useEffect(()=>{
        setCountCalls(props.countCalls)

    },[props.countCalls])
    return (
        <div className="containerHeaderInputCalls">
            <div className="InputHeader">{`Входящие (${getCountCalls})`}</div>
            <div
                onClick={() => {
                    props.setGroup(groupList[0])
                    setGroup('none')
                }}
                style={{ display: getGroup }} className="InputHeaderFilters">
                {props.group.name}
            </div>
            <div
                onClick={() => {
                    props.setTypeCall('Все')
                    setTypeCall('none')
                }}
                style={{ display: getTypeCall }} className="InputHeaderFilters">
                {props.typeCall}
            </div>
            <div
                onClick={() => {
                    props.setComment('Все')
                    setComment('none')
                }}
                style={{ display: getComment }} className="InputHeaderFilters">
                {props.comment}
            </div>
            <div
                onClick={() => {
                    props.setFirstCall('Все')
                    setFirstCall('none')
                }}
                style={{ display: getFirstCall }} className="InputHeaderFilters">
                {props.firstCall}
            </div>
            <div
                onClick={() => {
                    props.setRedirect('Все')
                    setRedirect('none')
                }}
                style={{ display: getRedirect }} className="InputHeaderFilters">
                {props.redirect}
            </div>

        </div>
    )

}
export default HeaderInputCalls