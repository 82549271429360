import { useState } from "react"
import { address } from "../../../config"
const commentCheck = (cm) => {
    if (cm !== null)
        return cm
    else
        return '...'
}
const Comment = (props) => {

    const [editModeComment, setEditModeComment] = useState('hidden')
    const [comment, setComment] = useState(commentCheck(props.comment))
    const endEditComment = (value, t) => {
        setEditModeComment('hidden')
        if (value.trim() === '') {
            setComment('...')
            if (t) fetch(`${address}/set-comment?id=${props.id}&group=${props.group}`)
        }
        else {
            setComment(value)
            fetch(`${address}/set-comment?id=${props.id}&group=${props.group}&comment=${value}`)
        }
    }
    const endEditCommentNoChange = (value, t) => {
        setEditModeComment('hidden')
        if (value.trim() === '')
            setComment('...')
        else setComment(value)
    }
    return (<span onClick={() => {
        setEditModeComment('text')
        setComment('')
    }}>
        {comment}
        <input onBlur={(e) => endEditCommentNoChange(e.target.value)}
            type={editModeComment}
            onKeyUp={(e) => {
                if (e.code === 'Enter') endEditComment(e.target.value, true)
            }}
        />

    </span>)

}
export default Comment