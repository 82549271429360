import { address } from "../../../config"
import { useState, useRef, useEffect } from 'react'
import Slider from './slider/Slider'
import ControlPanel from './controls/ControlPanel'
const Player = (props) => {
    const [percentage, setPercentage] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [getRecord, setRecord] = useState()
    useEffect(() => {
        if (props.record !== undefined) {
            setRecord(props.record)
            if (isPlaying) setIsPlaying(false)
        }
    }, [props.record])
    useEffect(() => {
        if (props.record !== undefined) {
            play()
        }
    }, [duration])

    const audioRef = useRef()

    const onChange = (e) => {
        const audio = audioRef.current
        audio.currentTime = (audio.duration / 100) * e.target.value
        setPercentage(e.target.value)
    }

    const play = () => {
        const audio = audioRef.current
        audio.volume = 1.0
        if (!isPlaying) {
            setIsPlaying(true)
            audio.play()
        }

        if (isPlaying) {
            setIsPlaying(false)
            audio.pause()
        }
    }
    const right = () => {
        const audio = audioRef.current
        audio.currentTime += 5
    }
    const left = () => {
        const audio = audioRef.current
        audio.currentTime -= 5
    }
    const getCurrDuration = (e) => {

        const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
        const time = e.currentTarget.currentTime
        if (!isNaN(percent)) {
            setPercentage(+percent)
            setCurrentTime(time.toFixed(2))
        }
    }
     return (
        <div className='app-container'>
            <Slider percentage={percentage} onChange={onChange} />
            <audio
                ref={audioRef}
                onTimeUpdate={getCurrDuration}
                onLoadedData={(e) => {
                    setDuration(e.currentTarget.duration.toFixed(2))
                }}
                src={getRecord}
            ></audio>
            <ControlPanel
                play={play}
                isPlaying={isPlaying}
                duration={duration}
                currentTime={currentTime}
                right={right}
                left={left}
                rec={getRecord}

            />          
        </div>
    )
}
export default Player
