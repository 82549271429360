import { Button,Form } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"
const Login = (props) => {
    const pass = { l: null, p: null }
    const navigate = useNavigate()
    const login = () => {
        if ((pass.l === 'azat' && pass.p === 'admin') || (pass.l === 'denis' && pass.p === 'dens') || (pass.l === 'tatyana' && pass.p === 'teach1') || (pass.l === 'andrei' && pass.p === 'andre700')
        || (pass.l === 'diana' && pass.p === 'd33use')) {
            props.setIsLoggedIn(true);
            navigate("/")  //check login
        }
        else alert('Неверный логин или пароль')
    }
    const pressKey = (e) => {
        if (e.key === 'Enter') {
            login()
        }
    }
    return (
        <div className="container min-vh-100  d-flex justify-content-center align-items-center" >
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Пользователь</Form.Label>
                    <Form.Control onBlur={(e) => { pass.l = e.target.value }} type="email" placeholder="Имя пользователя" />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control onKeyUp={(e) => { pass.p = e.target.value; pressKey(e) }} onBlur={(e) => { pass.p = e.target.value }} type="password" placeholder="Пароль" />
                </Form.Group>
                <div className="row justify-content-center">
                    <Form.Group className="mb-3" controlId="inputButton">
                        <Button onClick={() => { login() }}>Войти</Button>
                    </Form.Group>
                </div>
            </Form>
        </div>
    );

}
export default Login