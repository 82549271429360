export const dateWithoutTime = (dt) => {
    const date = new Date(dt)
    return (`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
}
export const standartNumber = (num) => {
    if (num === 'GSM-trunk8' ||num === 'GSM-trunk4'  ) return 'GSM-шлюз'
    if (num.length === 11)
        return `${num[0]} (${num[1]}${num[2]}${num[3]}) ${num[4]}${num[5]}${num[6]}-${num[7]}${num[8]}-${num[9]}${num[10]}`
    else if (num.length === 7)
        return `${num[0]}${num[1]}${num[2]}-${num[3]}${num[4]}-${num[5]}${num[6]}`
    if (num.length === 10)
        return `${num[3]}${num[4]}${num[5]}-${num[6]}${num[7]}-${num[8]}${num[9]}`
    else return num
}

const monthNumberToWord = (m) => {
    switch (m) {
        case 0:
            return 'Января'
        case 1:
            return 'Февраля'
        case 2:
            return 'Марта'
        case 3:
            return 'Апреля'
        case 4:
            return 'Мая'
        case 5:
            return 'Июня'
        case 6:
            return 'Июля'
        case 7:
            return 'Августа'
        case 8:
            return 'Сентября'
        case 9:
            return 'Октября'
        case 10:
            return 'Ноября'
        case 11:
            return 'Декабря'
    }
}
const setZero = (n) => {
    if (n < 9) return `0${n}`
    else return `${n}`
}
export const dateWithTime = (dt) => {
    const date = new Date(dt)

    return (`${date.getDate()} 
    ${monthNumberToWord(date.getMonth())}
    ${date.getFullYear()} 
    ${setZero(date.getHours())}:${setZero(date.getMinutes())}:${setZero(date.getSeconds())}`)
}
export const groupToWord = (gr) => {
    switch (gr.substring(0, 2)) {
        case '50':
            return 'магСБ'
        case '55':
            return 'серСБ'
        case '90':
            return 'магГБ'
        case '95':
            return 'серГБ'
        case '20':
            return 'магЮР'
        case '80':
            return 'магКр'
        case '85':
            return 'серКр'
        default:
            return '~'
    }
}
export const groupToWordShort = (gr) => {
    if (gr !== undefined)
        switch (gr.substring(0, 2)) {
            case '50':
                return 'м.сб'
            case '55':
                return 'с.сб'
            case '90':
                return 'м.гб'
            case '95':
                return 'с.гб'
            case '20':
                return 'м.юр.'
            case '80':
                return 'м.кр'
            case '85':
                return 'с.кр'
            case '10':
                return 'опт.'
            default:

                return '~'
        }
}
