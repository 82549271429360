import { useState, useEffect } from "react"
const Wait = (props) => {
    const [getVision, setVision] = useState('none')
    useEffect(() => {
        if (props.vision === false) setVision('none')
        if (props.vision === true) setVision('')
    }, [props])
    return (<div className="waitWindow" style={{ display: getVision }}>
        <h1 className="waitWord">Ожидайте...</h1>
    </div>)
}
export default Wait