import { useState, useEffect } from "react"
import { groupList } from "../../../config"
const HeaderOutputCalls = (props) => {
    const [getGroup, setGroup] = useState('none')
    const [getTypeCall, setTypeCall] = useState('none')
    const [getComment, setComment] = useState('none')
    const [getCountCalls, setCountCalls] = useState(0)
    useEffect(() => {
        if (props.group.name !== 'Все') setGroup('')
        else setGroup('none')
    }, [props.group.name])
    useEffect(() => {
        if (props.typeCall !== 'Все' && props.typeCall !== undefined) setTypeCall('')
        else setTypeCall('none')
    }, [props.typeCall])
    useEffect(() => {
        if (props.comment !== 'Все' && props.comment !== undefined && props.comment !== 'Все') setComment('')
        else setComment('none')
    }, [props.comment])
    useEffect(()=>{
        setCountCalls(props.countCalls)
    },[props.countCalls])
    return (
        <div className="containerHeaderInputCallsOutput">
            <div className="InputHeaderOutput">{`Исходящие (${getCountCalls})`}</div>
            <div
                onClick={() => {
                    props.setGroup(groupList[0])
                    setGroup('none')
                }}
                style={{ display: getGroup }} className="InputHeaderFiltersOutput">
                {props.group.name}
            </div>
            <div
                onClick={() => {
                    props.setTypeCall('Все')
                    setTypeCall('none')
                }}
                style={{ display: getTypeCall }} className="InputHeaderFiltersOutput">
                {props.typeCall}
            </div>
            <div
                onClick={() => {
                    props.setComment('Все')
                    setComment('none')
                }}
                style={{ display: getComment }} className="InputHeaderFiltersOutput">
                {props.comment}
            </div>
      

        </div>
    )

}
export default HeaderOutputCalls