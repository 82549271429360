import { Table } from 'react-bootstrap';
import { useEffect, useState } from 'react'
import { dateWithoutTime, dateWithTime, groupToWordShort } from '../../../func'
import { address } from '../../../config'
import Comment from '../Comment';
import imgPlayBlue from '../../../images/playBlue.png'
import imgPlayGreen from '../../../images/playGreen.png'
import imgDownloadBlue from '../../../images/downloadBlue.png'
import imgDownloadGreen from '../../../images/downloadGreen.png'
import imgInputCall from '../../../images/inputCall.png'
import imgOutpuCall from '../../../images/outputCall.png'
import fileDownload from 'js-file-download'
import Axios from 'axios'

const DurationCall = (props) => {
    const [getPlay, setPlay] = useState(imgPlayBlue)
    const [getDownload, setDownload] = useState(imgDownloadBlue)
    let waitSec = 0, durationSec = 0, waitMin = 0, durationMin = 0
    for (let i = 0; i < props.billsec.length; i++) {
        durationSec += props.billsec[i]
        if (props.billsec[i] !== 0 && waitSec === 0)
            waitSec = props.duration[i] - props.billsec[i]
    }
    if (durationSec === 0) {
        let wordType = 'пропущенный'
        if (props.type === 'out') wordType = 'не взял'
        return <>
            <td className='td' style={{ color: 'brown', fontSize: 'x-small' }}>{wordType}</td>
            <td className='td' style={{ color: 'brown', fontSize: 'x-small' }}>{wordType}</td>
        </>
    }
    for (let i = durationSec; i > 59; i -= 60, durationSec -= 60)
        ++durationMin
    for (let i = waitSec; i > 59; i -= 60, waitSec -= 60)
        ++waitMin
    const stringTime = (m, s) => {
        if (m !== 0) return (`${m}м ${s}с`)
        else return (`${s}с`)
    }
    return (
        <>
            <td className='td' style={{ width: '10%' }}>{`${stringTime(waitMin, waitSec)}`}</td>
            <td className='td' style={{ width: '20%' }}>
                <div className='container'>
                    <h6 className='callTextC'> {`${stringTime(durationMin, durationSec)}`}</h6>
                    <img className='imgC' width='16' height='16' src={getPlay} onClick={() => {
                        setPlay(imgPlayGreen)
                        props.record(`${address}/get-audio?date=${props.date}&trank=${props.trank}&path=${props.recordingfile}`)

                    }}></img>
                    <img className='imgC' width='12' height='12' src={getDownload} onClick={() => {
                        const rec = `${address}/get-audio?date=${props.date}&trank=${props.trank}&path=${props.recordingfile}`
                        Axios({
                            url: rec.replace('get-audio', 'download'),
                            method: "GET",
                            responseType: "blob"
                        }).then((res) => {
                            fileDownload(res.data, rec.substring(rec.indexOf('path=') + 5, rec.length))
                        })
                        setDownload(imgDownloadGreen)

                    }}></img>
                </div>
            </td >

        </>
    )
}
let countRender = 0
const ClientCalls = (props) => {
    const [getDataCalls, setDataCalls] = useState([])
    const fetchInputCalls = (num) => {
        fetch(`${address}/get-number?number=${num}`)
            .then(res => res.json()).then((result) => {
                const sorted = result.sort((date1, date2) => date1['calldate'] < date2['calldate'] ? 1 : -1);
                setDataCalls(sorted)

            })
    }
    useEffect(() => {
        if (countRender !== 1) fetchInputCalls(props.number)
        ++countRender
    }, [props])
    const checkTrank = (trank) => {
        for (let i = 0; i < trank.length; i++)
            if (trank[i] !== undefined && trank[i].length > 1) return trank[i]
        return undefined
    }
    return (<div className='window'>
        <Table className='table' style={{ width: '100%' }} striped bordered hover>
            <thead>
                <tr>
                    <th>Дата</th>
                    <th>Группа</th>
                    <th>Ожидание</th>
                    <th>Разговор</th>
                    <th>Комментарий</th>
                </tr>
            </thead>
            <tbody>
                {
                    getDataCalls.map((item, i) => {
                        if (item.type === 'inp')
                            return <tr key={i}>
                                <td className='td'>{dateWithTime(item.calldate)}</td>
                                <td className='td'>
                                    <div className='container'>
                                        <h6 className='callGroup'> {groupToWordShort(checkTrank(item.trank))}</h6>
                                        <img className='imgC' width='16' height='16' src={imgInputCall} ></img>
                                    </div>
                                </td>
                                <DurationCall billsec={item.billsec}
                                    disposition={item.disposition}
                                    duration={item.duration}
                                    recordingfile={item.recordingfile}
                                    trank={item.trank[0]}
                                    date={item.calldate}
                                    record={props.record}
                                ></DurationCall>
                                <td className='td' style={{ width: '20%' }}>
                                    <Comment id={item.id} comment={item.comment} group={checkTrank(item.trank)} />
                                </td>
                            </tr>
                        else if (item.type === 'out')
                            return <tr key={i}>
                                <td className='td'>{dateWithTime(item.calldate)}</td>
                                <td className='td'>
                                    <div className='container'>
                                        <h6 className='callGroup'> {groupToWordShort(item.caller)}</h6>
                                        <img className='imgC' width='16' height='16' src={imgOutpuCall} ></img>
                                    </div>
                                </td>
                                <DurationCall billsec={item.billsec}
                                    disposition={item.disposition}
                                    duration={item.duration}
                                    recordingfile={item.recordingfile}
                                    type={'out'}
                                ></DurationCall>
                                <td className='td' style={{ width: '20%' }}>
                                    <Comment id={item.id} comment={item.comment} group={item.trank[0]} />
                                </td>
                            </tr>
                    })}
            </tbody>
        </Table>
    </div>

    )

}
export default ClientCalls