import { Table, OverlayTrigger, Popover } from 'react-bootstrap'
import Comment from '../../Components/Comment'
import { address } from '../../../config'
import { useEffect, useState } from 'react'
import { dateWithoutTime, dateWithTime, groupToWord, standartNumber } from '../../../func'
import imgPlayBlue from '../../../images/playBlue.png'
import imgPlayGreen from '../../../images/playGreen.png'
import imgDownloadBlue from '../../../images/downloadBlue.png'
import imgDownloadGreen from '../../../images/downloadGreen.png'
import ClientCalls from '../../Components/ClientCalls'
import Wait from '../../Components/Wait'
import fileDownload from 'js-file-download'
import Axios from 'axios'
const billCount = (tr) => {
    let count = 1
    for (let i = 1, len = tr.length; i < len; i++)
        if (tr[i].substring(0, 2) !== tr[i - 1].substring(0, 2)) ++count
    return count
}
const checkBill = (bills) => {
    for (let i = 0; i < bills.length; i++)
        if (bills[i] > 0) return true
    return false
}
const WaitCall = (props) => {
    let waitSec = 0, durationSec = 0, waitMin = 0, durationMin = 0
    for (let i = 0; i < props.billsec.length; i++) {
        durationSec += props.billsec[i]
        if (props.billsec[i] !== 0 && waitSec === 0)
            waitSec = props.duration[i] - props.billsec[i]
    }
    const stringTime = (m, s) => {
        if (m !== 0) return (`${m}м ${s}с`)
        else return (`${s}с`)
    }
    if (durationSec === 0) {

        return <div className='td' style={{ color: 'brown', fontSize: 'x-small' }}>не взял</div>
    }
    for (let i = durationSec; i > 59; i -= 60, durationSec -= 60)
        ++durationMin
    for (let i = waitSec; i > 59; i -= 60, waitSec -= 60)
        ++waitMin

    return <div className='td'>{`${stringTime(waitMin, waitSec)}`}</div>
}
const DurationCall = (props) => {
    const [getPlay, setPlay] = useState(imgPlayBlue)
    const [getDownload, setDownload] = useState(imgDownloadBlue)
    const [getData, setData] = useState({ path: props.recordingfile, date: props.date, trank: props.trank })
    let waitSec = 0, durationSec = 0, waitMin = 0, durationMin = 0
    for (let i = 0; i < props.billsec.length; i++) {
        durationSec += props.billsec[i]
        if (props.billsec[i] !== 0 && waitSec === 0)
            waitSec = props.duration[i] - props.billsec[i]
    }
    if (durationSec === 0) {
        return <div className='td' style={{ color: 'brown', fontSize: 'x-small' }}>не взял</div>
    }
    for (let i = durationSec; i > 59; i -= 60, durationSec -= 60)
        ++durationMin
    for (let i = waitSec; i > 59; i -= 60, waitSec -= 60)
        ++waitMin
    const stringTime = (m, s) => {
        if (m !== 0) return (`${m}м ${s}с`)
        else return (`${s}с`)
    }
    return (
        <div className='container'>
            <h6 className='callText'> {`${stringTime(durationMin, durationSec)}`}</h6>
            <img key={props.id} className='img' width='24' height='24' src={getPlay} onClick={() => {
                if (getData.path !== undefined && getData.trank !== undefined && getData.date !== undefined)
                    props.record(`${address}/get-audio?date=${getData.date}&trank=${props.group}&path=${getData.path}`)
                setPlay(imgPlayGreen)
            }}></img>
            <img style={{ marginTop: '2px' }} className='img' width='20' height='20' src={getDownload} onClick={() => {
                if (getData.path !== undefined && getData.trank !== undefined && getData.date !== undefined) {

                    const rec = `${address}/get-audio?date=${getData.date}&trank=${props.group}&path=${getData.path}`
                    props.vision(true)
                    Axios({
                        url: rec.replace('get-audio', 'download'),
                        method: "GET",
                        responseType: "blob"
                    }).then((res) => {
                        fileDownload(res.data, rec.substring(rec.indexOf('path=') + 5, rec.length))
                        props.vision(false)
                    })
                }
                setDownload(imgDownloadGreen)
            }}></img>
        </div>
    )
}
let countRender = 0
const Caller = (props) => {//num, uniq
    if (props.count === 1)
        return (<h5 className='num'>{`${standartNumber(props.num)}`}</h5>)
    else
        return (
            <OverlayTrigger rootClose={true}
                trigger='click'
                placement='right'
                overlay={
                    <Popover show={false} className='windowClientCalls'>
                        <Popover.Header as="h3">{`Абонент: ${standartNumber(props.num)}`}</Popover.Header>
                        <Popover.Body>
                            <ClientCalls record={props.record} number={props.num}></ClientCalls>
                        </Popover.Body>
                    </Popover>
                }>
                <div className='container'>
                    <a className='span'>{`${standartNumber(props.num)}`}</a>
                    <h5 className='count'>{`(${props.count})`}</h5>
                </div>

            </OverlayTrigger>
        )
}
const ListOuputCalls = (props) => {
    const [getVision, setVision] = useState(false)
    const [getDataCalls, setDataCalls] = useState([])
    useEffect(() => {
        if (countRender !== 1) fetchInputCalls()
        ++countRender
    }, [props])
    const fetchInputCalls = () => {
        let num = ''
        setVision(true)
        if (props.number !== undefined && props.number !== '') num = `&number=${props.number}`
        fetch(`${address}/get?type=output&dateBegin=${dateWithoutTime(props.date.begin)}&dateEnd=${dateWithoutTime(props.date.end)}&group=${props.group.id}&comment=${props.comment}${num}`)
            .then(res => res.json()).then((result) => {
                const sorted = result.sort((date1, date2) => date1['calldate'] < date2['calldate'] ? 1 : -1);
                setDataCalls(sorted)
                setVision(false)
                props.countCalls(sorted.length)
            })
    }
    const checkNumber = (num, rec) => {
        if (num.length > 0) return num
        else return (rec.substring(rec.indexOf('-') + 1,
            rec.indexOf('-', 4)))

    }
    return (
        <>
            <Wait vision={getVision}></Wait>
            <Table style={{ marginTop: '60px', width: '100%' }} striped bordered hover>
                <thead>
                    <tr align='center'>
                        <th className='th'>{'Дата'}</th>
                        <th className='th'>{'Исх.номер'}</th>
                        <th className='th'>{'Группа'}</th>
                        <th className='th'>{'Телефон клиента'}</th>
                        <th className='th'>{'Ожидание'}</th>
                        <th className='th'>{'Разговор'}</th>
                        <th className='th'>{'Комментарий'}</th>
                    </tr>
                </thead>
                <tbody>
                    {getDataCalls.map((item, i) => {
                        const count = billCount(item.trank)
                        const billsFlag = checkBill(item.billsec)
                        const element = <tr key={i}>
                            <td className='td' style={{ width: '15%' }}>{dateWithTime(item.calldate)}</td>
                            <td className='td' style={{ width: '12%' }}>{`${standartNumber(item.trank[0])}`}</td>
                            <td className='td' style={{ width: '5%' }}>{groupToWord(item.caller)}</td>
                            <td className='td' style={{ width: '13%' }}>
                                <Caller record={props.record} num={checkNumber(item.destination, item.recordingfile)} uniq={item.uniq} count={item.count}></Caller>
                            </td>
                            <td key={item.id + 'w'} className='td' style={{ width: '3%' }}>
                                <WaitCall
                                    billsec={item.billsec}
                                    duration={item.duration}
                                ></WaitCall>
                            </td>
                            <td key={item.id + 'd'} className='td' style={{ width: '10%' }}>
                                <DurationCall
                                    billsec={item.billsec}
                                    disposition={item.disposition}
                                    duration={item.duration}
                                    recordingfile={item.recordingfile}
                                    trank={item.trank[0]}
                                    date={item.calldate}
                                    record={props.record}
                                    vision={setVision}
                                    group={item.caller}
                                ></DurationCall>
                            </td>
                            <td key={item.id + 'c'} className='td' style={{ width: '20%' }}>
                                <Comment id={item.id} comment={item.comment} group={item.trank[0]} />
                            </td>

                        </tr>
                        if (props.firstCall === 'Первичный' && !item.uniq) return null
                        if (props.firstCall === 'Повторный' && item.uniq) return null
                        switch (props.typeCall) {
                            case undefined: return element
                            case 'Все':
                                if (props.redirect === undefined || props.redirect === 'Все')
                                    return element
                                if (props.redirect === 'Без перевода')
                                    if (count === 1) return element
                                if (props.redirect === 'Переведенные')
                                    if (count > 1) return element
                                break
                            case 'Пропущенные':
                                if (!billsFlag) return element
                                break
                            case 'Принятые':
                                if (billsFlag) return element
                                break
                        }
                    })}
                </tbody>
            </Table>

        </>
    )

}
export default ListOuputCalls
