import { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { groupList } from '../../../config';
import CalendarWindow from '../../Components/CalendarWindow'
import { dateWithoutTime } from '../../../func'
import imgSearch from '../../../images/search.png'
import imgNull from '../../../images/null.png'
const typeList = ['Все', 'Принятые', 'Пропущенные']
const commnetList = ['Все', 'Комментарий', 'Без комментария']
const firstCallList = ['Все', 'Первичный', 'Повторный']
const redirectList = ['Все', 'Без перевода', 'Переведенные']
const SearchInputCalls = (props) => {
    const [getSearchWord, setSearchWord] = useState('')
    const [getDate, setDate] = useState({ visible: false, begin: Date.now(), end: Date.now() })
    const [getGroup, setGroup] = useState(groupList[0])
    const [getType, setType] = useState(typeList[0])
    const [getRedirect, setRedirect] = useState(redirectList[0])
    const [getComment, setComment] = useState(commnetList[0])
    const [getFirstCall, setFirstCall] = useState(firstCallList[0])
    const [showFilters, setShowFilters] = useState({ visibility: 'hidden' })
    const [getDropdownRedirect, setDropdownRedirect] = useState({ disabled: false })
    useEffect(() => {
        if (props.getGroup !== undefined) setGroup(props.getGroup)
    }, [props.getGroup])
    useEffect(() => {
        if (props.getTypeCall !== undefined) setType(props.getTypeCall)
    }, [props.getTypeCall])
    useEffect(() => {
        if (props.getComment !== undefined) setComment(props.getComment)
    }, [props.getComment])
    useEffect(() => {
        if (props.getFirstCall !== undefined) setFirstCall(props.getFirstCall)
    }, [props.getFirstCall])
    useEffect(() => {
        if (props.getRedirect !== undefined) setRedirect(props.getRedirect)
    }, [props.getRedirect])
    const selectDate = (value) => setDate(value)
    useEffect(() => {
        if (getType === typeList[2]) {
            setRedirect(redirectList[0])
            setDropdownRedirect({ disabled: true })
        }
        else {
            setDropdownRedirect({ disabled: false })
        }
    }, [getType])
    const [getSearchImage, setSearchImage] = useState(imgSearch)
    return (<>
        <CalendarWindow date={getDate} selectDate={selectDate}></CalendarWindow>
        <img src={getSearchImage} className='imgSearchInput'></img>
        <input onKeyDown={(e) => {
            if (e.key === 'Enter') {
                setShowFilters({ visibility: 'hidden' })
                props.group(getGroup)
                props.typeCall(getType)
                props.comment(getComment)
                props.firstCall(getFirstCall)
                props.date(getDate)
                props.redirect(getRedirect)
                props.number(getSearchWord)
            }
        }} onChange={(e) => {
            setSearchWord(e.target.value);
            if (e.target.value === '')
                setSearchImage(imgSearch)
            else setSearchImage(imgNull)
        }} onClick={() => {
            setShowFilters({ visibility: 'visible' })
        }} className='searchInp' type='text' placeholder={'      Введите номер абонента'} value={getSearchWord}
            style={{ background: getSearchImage }}></input>
        <div className='filters' style={showFilters} >
            <h6 className='filterElement'>{'Дата:'}</h6>
            <Button className='filterButton' variant='outline-primary'
                onClick={() => {
                    setDate({ visible: true, begin: getDate.begin, end: getDate.end })

                }}>{`${dateWithoutTime(getDate.begin)} – ${dateWithoutTime(getDate.end)}`}</Button>

            <h6 className='filterElement'>{'Группа:'}</h6>
            <DropdownButton className='filterButton' variant="outline-primary" id="dropdown-basic-button" title={getGroup.name}>
                {groupList.map((item, i) =>
                    <Dropdown.Item key={i} onClick={() =>
                        setGroup(item)
                    }>{item.name}</Dropdown.Item>
                )}
            </DropdownButton>

            <h6 className='filterElement'>Принятые\пропущенные:</h6>
            <DropdownButton className='filterButton' variant="outline-primary" id="dropdown-basic-button" title={getType}>
                {typeList.map((item, i) =>
                    <Dropdown.Item key={i} onClick={() =>
                        setType(item)
                    }>{item}</Dropdown.Item>
                )}
            </DropdownButton>

            <h6 className='filterElement'>Переведенные:</h6>
            <DropdownButton disabled={getDropdownRedirect.disabled} className='filterButton' variant="outline-primary" id="dropdown-basic-button" title={getRedirect}>
                {redirectList.map((item, i) =>
                    <Dropdown.Item key={i} onClick={() =>
                        setRedirect(item)
                    }>{item}</Dropdown.Item>
                )}
            </DropdownButton>

            <h6 className='filterElement'>Комментарий</h6>
            <DropdownButton className='filterButton' variant="outline-primary" id="dropdown-basic-button" title={getComment}>
                {commnetList.map((item, i) =>
                    <Dropdown.Item key={i} onClick={() =>
                        setComment(item)
                    }>{item}</Dropdown.Item>
                )}
            </DropdownButton>

            <h6 className='filterElement' >Первичный звонок</h6>
            <DropdownButton className='filterButton' variant="outline-primary" id="dropdown-basic-button" title={getFirstCall}>
                {firstCallList.map((item, i) =>
                    <Dropdown.Item key={i} onClick={() =>
                        setFirstCall(item)
                    }>{item}</Dropdown.Item>
                )}
            </DropdownButton>

            <Button onClick={() => {

                setShowFilters({ visibility: 'hidden' })
                props.group(getGroup)
                props.typeCall(getType)
                props.comment(getComment)
                props.firstCall(getFirstCall)
                props.date(getDate)
                props.redirect(getRedirect)
                props.number(getSearchWord)

            }} className='acceptButton' variant='primary'>Поиск</Button>

            <Button onClick={() => {
                setShowFilters({ visibility: 'hidden' })
            }} className='acceptButton' variant='secondary'>Отмена</Button>
        </div >
    </>);
}
export default SearchInputCalls