import { Container, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import WindowStat from './Window/Stat';
import WindowCallsInput from './Window/InputCalls';
import WindowOutputCalls from './Window/OutputCalls';
import WindowReports from './Window/Reports'
import imgPhone from "./images/phone.png"
import imgDiagram from "./images/diagram.png"
import imgStat from "./images/stat.png"
import Login from './Window/Components/Login';
import { Route, Routes } from 'react-router-dom';
const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const colorStandart = 'rgba(21, 36, 119, 0.158)'
    const colorSelect = 'rgba(21, 36, 119, 1)'
    const [workplace, setWorkplace] = useState('WindowCallsInput')
    const [getColorStat, setColorStat] = useState(colorStandart)
    const [getColorInput, setColorInput] = useState(colorSelect)
    const [getColorOutput, setColorOutput] = useState(colorStandart)
    const MainWindow = (props) => {

        switch (workplace) {
            case 'WindowStat':
                return <WindowStat />
            case 'WindowCallsInput':
                return (<>
                    <WindowCallsInput />
                </>)
            case 'WindowOutputCalls':
                return (<>
                    <WindowOutputCalls />
                </>)
            case 'WindowReports':
                return (<>
                    <WindowReports />
                </>)
        }
    }
    /*
    <button
                            style={{ background: getColorStat }}
                            onClick={(e) => {
                                setWorkplace('WindowStat')
                                setColorStat(colorSelect)
                                setColorInput(colorStandart)
                                setColorOutput(colorStandart)
                            }}
                            className='mainMenuButton' >
                            <img src={imgDiagram}></img>
                            {` Статистика`}
                        </button>
    */
   const checkLogin = () =>{
    if(isLoggedIn) return(
        <Container fluid >
                    <Row className='mainPageRow' >
                        <Col className='mainMenuCol' sm={3} xs={3} >
                            <h2
                                onClick={() => {
                                    window.location.reload()
                                }}
                                className='brand' style={{ cursor: 'pointer', marginTop: '15px', textAlign: 'center' }}>
                                {`JapCalls`}

                            </h2>

                            <h3 className='callsName'>
                                <img width='16' height='16' src={imgPhone}></img>
                                {` Звонки:`}
                            </h3>
                            <button
                                onClick={(e) => {
                                    setWorkplace('WindowCallsInput')
                                    setColorStat(colorStandart)
                                    setColorInput(colorSelect)
                                    setColorOutput(colorStandart)
                                }}
                                className='mainMenuButton' style={{ background: getColorInput, marginTop: '1vh', marginLeft: '10px' }}>
                                {` -Входящие`}
                            </button>
                            <button className='mainMenuButton'
                                onClick={(e) => {
                                    setWorkplace('WindowOutputCalls')
                                    setColorStat(colorStandart)
                                    setColorInput(colorStandart)
                                    setColorOutput(colorSelect)
                                }}
                                style={{ background: getColorOutput, marginTop: '1vh', marginLeft: '10px' }}>
                                {` -Исходящие`}
                            </button>


                        </Col>
                        <Col className='mainWindowCol' sm={9} xs={9} >
                            <MainWindow></MainWindow>


                        </Col>

                    </Row>

                </Container>        
    )
    else return (
        <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
    )
   }
    return (
        <Routes>
            <Route path="/" element={
                checkLogin()
            } />
            <Route path="login" element={
                <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            } />
        </Routes>


    )
    /*
    <button className='mainMenuButton'
                            onClick={(e) => {
                                setWorkplace('WindowReports')
                            }} style={{ marginTop: '1vh' }}>
                            <img width='16' height='16' src={imgStat}></img>
                            {` Отчёты`}
                        </button>
    */
}
export default App