import Calendar from 'react-calendar';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
const CalendarWindow = (props) => {
    const [timeValue, setTimeValue] = useState(new Date());
    const handleClose = () => props.selectDate(
        {
            visible: false,
            begin: props.date.begin,
            end: props.date.end
        })
    const handleSave = (begin, end) => {
        if(timeValue[0]=== undefined || timeValue[1] === undefined) handleClose()
        else
        props.selectDate(
            {
                visible: false,
                begin: timeValue[0],
                end: timeValue[1]
            }
        )
    }

    return (<>
        <Modal show={props.date.visible} onHide={handleClose}>
            <Modal.Header >
                <Modal.Title>Выберите временной промежуток</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Calendar className='calendar' selectRange={true}
                    onChange={setTimeValue}
                >
                </Calendar>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSave}>
                    Применить
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Отмена
                </Button>
            </Modal.Footer>
        </Modal>

    </>)
}
export default CalendarWindow
//onChange={setTimeValue} value={timeValue}