import { useState, useEffect } from 'react';
import ListCalls from './ListOutputCalls';
import Search from './SearchOutputCalls';
import DateCalls from '../Components/DateCalls';
import Player from '../Components/Player';
import Header from './HeaderOutputCalls'
import { groupList } from '../../config';

const OutputCalls = (props) => {
    const [getDate, setDate] = useState({ visible: false, begin: Date.now(), end: Date.now() })
    const [getGroup, setGroup] = useState(groupList[0])
    const [getTypeCall, setTypeCall] = useState()
    const [getComment, setComment] = useState()
    const [getFirstCall, setFirstCall] = useState()
    const [getRedirect, setRedirect] = useState()
    const [getNumber, setNumber] = useState()
    const [getRecord, setRecord] = useState()
    const [getCountCalls,setCountCalls] = useState(0)
    const record = (value) => setRecord(value)
    const date = (value) => setDate(value)
    const group = (value) => setGroup(value)
    const typeCall = (value) => setTypeCall(value)
    const comment = (value) => setComment(value)
    const firstCall = (value) => setFirstCall(value)
    const redirect = (value) => setRedirect(value)
    const number = (value) => setNumber(value)
    const countCalls = (value) => setCountCalls(value)

    return (
        <>
            <div className='top' style={{
                display: 'flex', width: '100%', position: 'fixed', background:
                    'linear-gradient(90deg, rgba(255,255,255,1.0) 35%, brown 65%'
            }}>
                <div style={{ width: '55%' }}>
                    <Header
                        group={getGroup}
                        setGroup={group}
                        setTypeCall={typeCall}
                        setComment={comment}
                        setFirstCall={firstCall}
                        setRedirect={redirect}
                        typeCall={getTypeCall}
                        comment={getComment}
                        countCalls={getCountCalls}
                    ></Header>
                    <div className='container'>
                        <Search className='search'
                            date={date}
                            group={group}
                            typeCall={typeCall}
                            comment={comment}
                            firstCall={firstCall}
                            redirect={redirect}
                            number={number}
                        ></Search>
                        <DateCalls
                            setDate={date}
                            date={getDate}
                        ></DateCalls>

                    </div>

                </div>
                <div style={{ width: '25%' }}>
                    <Player record={getRecord}></Player>
                </div>
            </div>
            <ListCalls
                setDate={date}
                date={getDate}
                group={getGroup}
                typeCall={getTypeCall}
                comment={getComment}
                firstCall={getFirstCall}
                redirect={getRedirect}
                number={getNumber}
                record={record}
                countCalls={countCalls}
            ></ListCalls>

        </>
    )

}
export default OutputCalls
