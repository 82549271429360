import React from 'react'
import './button.css'
import imgDownload from '../../../../images/downloadWhite.png'
import imgForward from '../../../../images/forward.png'
import imgBack from '../../../../images/back.png'
import fileDownload from 'js-file-download'
import Axios from 'axios'

function Button({ play, isPlaying, right, left,rec }) {
  return (
    <div className='btn-container'>
      <div style={{ paddingTop: '2px' }}>
        <img onClick={() => {
          left()
        }} className='move' src={imgBack}></img>
      </div>
      <div style={{ paddingLeft: '50px' }}>
        <div onClick={play} className={isPlaying ? 'btn-stop' : 'btn-play'}></div>
      </div>
      <div style={{ paddingLeft: '20px' }}>
        <img onClick={() => {
          Axios({
            url:rec.replace('get-audio','download'),
            method: "GET",
            responseType:"blob"
          }).then((res)=>{
            fileDownload(res.data,rec.substring(rec.indexOf('path=')+5,rec.length))
          })

        }} className='download' src={imgDownload}></img>
      </div>
      <div style={{ paddingLeft: '50px', paddingTop: '2px' }}>
        <img onClick={() => {
          right()
        }} className='move' src={imgForward}></img>
      </div>
    </div>
  )
}
export default Button
